.right-side {
    background-color: black;
    color: white;
    padding: 20px 20px 50px 20px;
    margin-top: -50px;
    min-width: 0px;
}

@media (max-width: 850px) {
    .right-side {
        /* stretch all the way */
        width: 100%;
        /* value for margin-left here would be: negative of (body.margin-left minus desired value) */
        margin: 30px 10px 10px -15px;
    }
}

.about,
.skills,
.contact-info,
.logos {
    color: gray;
    margin: 35px 0px 0px 0px;
}

.logos {
    margin: 50px 0px 0px 0px;
}

.about-text,
.skills-points,
.email,
.phone {
    color: white;
    line-height: normal;
}

/* BUG: JavaScript's order in this list doesn't match with the order it in my resume'd pdf.
right-side won't wrap properly based on screen size with more than 2 columns */
.skills-points {
    columns: 3;
    line-height: 20px;
}

.skill-point {
    padding: 0px 20px 0px 0px;
}

.email,
.phone,
.about-text a,
.logo {
    margin: 20px 0px 0px 0px;
    text-decoration: none;
    color: white;
}

.github-logo,
.linkedin-logo,
.download-logo {
    max-width: 40px;
    margin: 0px 10px 0px 0px;
}

@media (hover: hover) {

    .email:hover,
    .phone:hover,
    .about-text a:hover {
        color: lightgray;
    }
}
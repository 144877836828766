.profile-overview {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: fit-content;
    width: fit-content;
    align-items: center;
}

.memoji {
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
    border-radius: 100%;
    width: 80px;
}

.name {
    font-size: 35px;
    margin: 0px 0px 0px 0px;
}

.title {
    font-size: 18px;
    margin: 0px 0px 0px 5px;
    color: gray
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  padding: 0.5em 10px;
  margin: 0px 0px 0px 0px;
  width: fit-content;
}

blockquote-footer {
  font-size: 14px;
  color: #777;
}

.heading {
  margin: 50px 0px 30px 0px;
  font-size: 24px;
  font-weight: 900;
}

ul {
  line-height: 1.5;
}
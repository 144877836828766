.writings {
    /* common to all components being shown under the navbar */
    margin: 50px 25px 50px 25px;
}

.writings-list {
    list-style: none;
    width: fit-content;
}

.list-date {
    color: gray;
}

.list-title {
    margin-bottom: 20px;
}
.books-read {
    /* common to all components not being shown under the navbar */
    margin: 0px 25px 50px 25px;
}

.books-read-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 30px;
}

.book-grid-item {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.book-image {
    width: 150px;
    height: 200px;
}

.book-title {
    margin: 20px 10px 20px 0px;
}

.book-description {
    margin: 0px 10px 20px 0px;
}